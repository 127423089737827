<template>
	<div class="auth-wrapper auth-v2">
		<b-row class="auth-inner m-0">
			<!-- Brand logo-->
			<!-- <b-link class="brand-logo">
				<vuexy-logo />
				<h2 class="brand-text text-primary ml-1">MyID</h2>
			</b-link> -->
			<!-- /Brand logo-->

			<!-- Left Text-->
			<b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
				<div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
					<b-img fluid :src="imgUrl" alt="Login V2" />
				</div>
			</b-col>
			<!-- /Left Text-->

			<!-- Login-->
			<b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
				<b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
					<b-card-title class="mb-1 font-weight-bold" title-tag="h2"> MyID.box </b-card-title>
					<!-- form -->
					<validation-observer ref="loginForm" #default="{ invalid }">
						<b-form class="auth-login-form mt-2" @submit.prevent="login">
							<!-- email -->
							<b-form-group label="Логин" label-for="username">
								<validation-provider #default="{ errors }" name="Username" rules="required">
									<b-form-input
										id="username"
										v-model="username"
										:state="errors.length > 0 ? false : null"
										name="register-username"
										placeholder="Пароль"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- forgot password -->
							<b-form-group>
								<div class="d-flex justify-content-between">
									<label for="login-password">Пароль</label>
									<!-- <b-link :to="{ name: 'auth-forgot-password' }">
										<small>Forgot Password?</small>
									</b-link> -->
								</div>
								<validation-provider
									#default="{ errors }"
									name="Password"
									vid="password"
									rules="required"
								>
									<b-input-group
										class="input-group-merge"
										:class="errors.length > 0 ? 'is-invalid' : null"
									>
										<b-form-input
											id="login-password"
											v-model="password"
											:state="errors.length > 0 ? false : null"
											class="form-control-merge"
											:type="passwordFieldType"
											name="login-password"
											placeholder="Пароль"
										/>
										<b-input-group-append is-text>
											<feather-icon
												class="cursor-pointer"
												:icon="passwordToggleIcon"
												@click="togglePasswordVisibility"
											/>
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- checkbox -->
							<b-form-group>
								<b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
									Запомнить
								</b-form-checkbox>
							</b-form-group>

							<!-- submit buttons -->
							<b-button type="submit" variant="primary" block :disabled="invalid"> Войти </b-button>
						</b-form>
					</validation-observer>
				</b-col>
			</b-col>
			<!-- /Login-->
		</b-row>
	</div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
	BRow,
	BCol,
	BLink,
	BFormGroup,
	BFormInput,
	BInputGroupAppend,
	BInputGroup,
	BFormCheckbox,
	BCardText,
	BCardTitle,
	BImg,
	BForm,
	BButton,
	BAlert,
	VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
	directives: {
		'b-tooltip': VBTooltip,
	},
	components: {
		BRow,
		BCol,
		BLink,
		BFormGroup,
		BFormInput,
		BInputGroupAppend,
		BInputGroup,
		BFormCheckbox,
		BCardText,
		BCardTitle,
		BImg,
		BForm,
		BButton,
		BAlert,
		VuexyLogo,
		ValidationProvider,
		ValidationObserver,
	},
	mixins: [togglePasswordVisibility],
	data() {
		return {
			status: '',
			password: '',
			username: '',
			sideImg: require('@/assets/images/pages/login-v2.svg'),

			// validation rules
			required,
		}
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
		imgUrl() {
			if (store.state.appConfig.layout.skin === 'dark') {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
				return this.sideImg
			}
			return this.sideImg
		},
	},
	methods: {
		login() {
			this.$refs.loginForm.validate().then(success => {
				if (success) {
					useJwt
						.login({
							username: this.username,
							password: this.password,
						})
						.then(response => {
							const userData = response.data
							useJwt.setToken(response.data.token)
							useJwt.setRefreshToken(response.data.token)
							userData.ability = [
								{
									action: 'manage',
									subject: 'Admin',
								}
							]
							localStorage.setItem('userData', JSON.stringify(userData))
							this.$ability.update(userData.ability)
							this.$router.replace('/')
							// // ? This is just for demo purpose as well.
							// // ? Because we are showing eCommerce app's cart items count in navbar
							// this.$store.commit(
							// 	'app-ecommerce/UPDATE_CART_ITEMS_COUNT',
							// 	userData.extras.eCommerceCartItemsCount,
							// )

							// // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
							// this.$router.replace(getHomeRouteForLoggedInUser(userData.role)).then(() => {
							// 	this.$toast({
							// 		component: ToastificationContent,
							// 		position: 'top-right',
							// 		props: {
							// 			title: `Welcome ${userData.fullName || userData.username}`,
							// 			icon: 'CoffeeIcon',
							// 			variant: 'success',
							// 			text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
							// 		},
							// 	})
							// })
						})
						.catch(e => {
							if (e.response.data.non_field_errors) {
								this.$refs.loginForm.setErrors(e.response.data.non_field_errors)
							}
							this.$toast({
								component: ToastificationContent,
								props: {
								title: 'Login error',
								icon: 'InfoIcon',
								variant: 'danger',
								text: e.response.data.non_field_errors[0],
								},
							})
						})
				}
			})
		},
	},
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
